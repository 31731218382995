import React from 'react'
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { cartRemoveItem } from '../../../application/features/cart';

export const CartItem = (props) => {
    // props
    const { beat, licence } = props;

    // redux
    const dispatch = useDispatch();

    const handleRemoveFromCart = (e) => {
        e.stopPropagation();
        dispatch(cartRemoveItem({
            beat_id: beat.id,
            licence_id: licence.id,
        }));
    }
    
    return (
        <div className={styles.item}>
            <img src={beat.cover} alt="" />
            <div>
                <h6>{beat.name}</h6>
                <span>TRACK</span>
            </div>
            <span>{licence.price}</span>
            <button type='button' className="btn btn-text" onClick={handleRemoveFromCart}>X</button>
        </div>
    );
}
