import React from 'react'

export const IconVideos = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <path fill="none" d="M11 14L18 10 11 6z"></path>
        <path d="M4,8H2v12c0,1.103,0.897,2,2,2h12v-2H4V8z"></path>
        <path d="M20,2H8C6.896,2,6,2.896,6,4v12c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V4C22,2.896,21.104,2,20,2z M11,14V6l7,4 L11,14z"></path>
    </svg>
  )
}
