import React, { useEffect, useState } from 'react'
import styles from './index.module.scss';
import { PropTypes } from 'prop-types';
import { IconPlay } from '../../../../components/icons/play';
import { useSelector } from 'react-redux';
import { IconPause } from '../../../../components/icons/pause';

const FeaturedBeatCover = (props) => {
    // props
    const { cover, togglePlay, size, featuredBeatOverride } = props;

    // state
    const [beat, setBeat] = useState(null);

    // redux
    const isPlaying = useSelector(state => state.player.isPlaying);
    const currentSource = useSelector(state => state.player.source);
    const featuredBeat = useSelector(state => state.beats.featuredBeat);

    // effects
    useEffect(() => {
        if (featuredBeatOverride) {
            setBeat(featuredBeatOverride);
        } else {
            setBeat(featuredBeat);
        }
    }, [featuredBeat, featuredBeatOverride]);

    //handlers
    const handleTogglePlay = () => {
        console.log("Calling toggle play from the cover component");
        togglePlay();
    }
    return (
        <div className={styles.cover} style={{ height: size, width: size }}>
            <img src={cover} height={size} width={size} alt="" />
            
            <div className={styles.play} onClick={handleTogglePlay}>
                {!(isPlaying && beat && (beat.media == currentSource)) ? <IconPlay height="20px" width="20px" /> : <IconPause height="20px" width="20px" />}
            </div>
        </div>
    );
}

FeaturedBeatCover.defaultProps = {
    togglePlay: () => console.log("Toggle Play Triggered, Not Implemented"),
    size: 120,
}

FeaturedBeatCover.protoTypes = {
    togglePlay: PropTypes.func,
    size: PropTypes.number,
}

export default FeaturedBeatCover;