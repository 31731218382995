import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { StripeCheckoutForm } from "./stripe-checkout-form";
import { stripePaymentIntent } from "../../../application/features/cart";

const stripePromise = loadStripe(`${process.env.STRIPE_PUBLIC_KEY}`)
export const CheckoutStripe = () => {
    const [clientSecret, setClientSecret] = useState("");

    const intent = useSelector(state => state.cart.paymentIntent)
    const dispatch = useDispatch();

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        dispatch(stripePaymentIntent());
    }, []);

    // setClientSecret(data.clientSecret)

    useEffect(() => {
        if (!intent.data) return;
        console.log("setting the client secret ");
        setClientSecret(intent.data.clientSecret);
    }, [intent.data]);

    const appearance = {
        theme: 'night',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <StripeCheckoutForm />
                </Elements>
            )}
        </div>
    )
}