import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { blogFetchPosts } from '../../../application/features/blog';
import WebLayout from '../../components/layout/web';
import { useNavigate } from 'react-router-dom';

export const BlogPage = () => {
    // hooks
    const navigate = useNavigate();

    // redux 
    const dispatch = useDispatch();
    const posts = useSelector(state => state.blog.posts.data);

    // effects
    useEffect(() => {
        if (posts.length > 0) return;
        dispatch(blogFetchPosts());
    }, []);
    
    return (
        <WebLayout>
           <div className="container text-light">
                <div className="row">
                    <h1 className='text-light text-center my-5'>Blog Posts</h1>
                </div>
                <div className="row">
                    {posts.map(post => {
                        return (
                            <div className='col-lg-3' onClick={() => navigate(`${post.id}`)}>
                                <img src={post.featured_image} alt="" width="100%" />
                                <h3>{post.title}</h3>
                            </div>
                        )
                    })}
                </div>
           </div>
        </WebLayout>
    );
}
