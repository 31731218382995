import React from 'react'
import styles from './index.module.scss';

export const HomePageContactComponent = () => {
  return (
    <div className={styles.contact}>
        <div className='row'>
            <div className="col-lg mt-2">
                <input type="text" className='form-control' placeholder='Your Name' />
            </div>

            <div className="col-lg mt-2">
                <input type="text" className='form-control' placeholder='Email Address' />
            </div>
        </div>

        <div className='mt-2'>
            <input type="text" className='form-control' placeholder='Subject' />
        </div>

        <div className='mt-2'>
            <textarea className='form-control' placeholder='Message'></textarea>
        </div>

        <div className={styles.button}>
            <button className="btn btn-secondary">SEND MESSAGE</button>
        </div>
    </div>
  )
}
