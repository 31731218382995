import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BeatZone from "../../api/beatzone";
import { customFetch } from "../../core/network";



// ACTION CONSTANTS
const STRIPE_PAYMENT_INTENT = 'cart/stripe-payment-intent';

// ACTIONS
export const stripePaymentIntent = createAsyncThunk(
    STRIPE_PAYMENT_INTENT,
    async (data, store) => {
        return await customFetch('POST', BeatZone.stripe_payment_intent, { amount: 50});
    }
);

// INITIAL STATE
const initialState = {
    items: [], // {beat_id, licence_id},
    extra: [], //  { cover, title, type, description, price  }
    showLicenceModal: false,
    selectedBeat: null,
    paymentIntent: {
        isFetching: false,
        isFetchingSuccessful: false,
        fetchingError: null,
        data: []
    },
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        cartAddItem: (state, action) => {
            let index = state.items.findIndex(val => val.beat_id == action.payload.beat_id);
            if (index > -1) {
                if (state.items[index].licence_id == action.payload.licence_id) return;
                state.items[index] = action.payload;
                return;
            };
            state.items.push(action.payload);
        },
        cartRemoveItem: (state, action) => {
            let index = state.items.findIndex(val => val.beat_id == action.payload.beat_id);
            if (index == -1) return;
            state.items.splice(index, 1);
        },
        cartUpdateItem: (state, action) => {
            let index = state.items.findIndex(val => val.beat_id == action.payload.beat_id);
            if (!index) return;
            state.items[index] = action.payload;
        },
        cartShowLicenceModal: (state, action) => {
            state.showLicenceModal = true;
        },
        cartHideLicenceModal: (state, action) => {
            state.showLicenceModal = false;
        },
        cartSelectBeat: (state, action) => {
            state.selectedBeat = action.payload;
        }
    },
    extraReducers: {
        [stripePaymentIntent.pending]: (state, action) => {
            state.paymentIntent.isFetching = true;
            state.paymentIntent.isFetchingSuccessful = false;
            state.paymentIntent.fetchingError = null;
        },
        [stripePaymentIntent.fulfilled]: (state, action) => {
            state.paymentIntent.isFetching = false;
            state.paymentIntent.isFetchingSuccessful = true;
            state.paymentIntent.data = action.payload;
        },
        [stripePaymentIntent.rejected]: (state, action) => {
            state.paymentIntent.isFetching = false;
            state.paymentIntent.fetchingError = action.error;
        }
    },
});

export default cartSlice.reducer;
export const { cartAddItem, cartRemoveItem, cartUpdateItem, cartShowLicenceModal, cartHideLicenceModal, cartSelectBeat } = cartSlice.actions