
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { customFetch } from '../../core/network';
import BeatZone from '../../api/beatzone';

// ACTION CONSTANTS
const VIDEO_FETCH_VIDEOS = "video/FETCH_VIDEOS";

// ACTIONS
export const videoFetchVideos = createAsyncThunk(
    VIDEO_FETCH_VIDEOS,
    async (data, store) => {
        return customFetch('GET', BeatZone.videos);
    }
)

// INITIAL STATE
const initialState = {
    videos: {
        isFetching: false,
        isFetchingSuccessful: false,
        fetchingError: null,
        data: [],
    }
}

// SLICE
const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {},
    extraReducers: {
        [videoFetchVideos.pending]: (state, action) => {
            state.videos.isFetching = true;
            state.videos.isFetchingSuccessful = false;
            state.videos.fetchingError = null;
        },
        [videoFetchVideos.fulfilled]: (state, action) => {
            state.videos.isFetching = false;
            state.videos.isFetchingSuccessful = true;
            state.videos.data = action.payload.data ?? [];
        },
        [videoFetchVideos.rejected]: (state, action) => {
            state.videos.isFetching = false;
            state.videos.fetchingError = action.error;
        }
    },
});

export default videoSlice.reducer;