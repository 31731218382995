import React from 'react'

export const IconPost = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <g>
            <path d="M3.948,5.114c0.213-0.085,0.341-0.303,0.312-0.53C4.23,4.357,4.05,4.179,3.822,4.152L2.558,4.003   c-0.27-0.034-0.521,0.162-0.555,0.435l-2,16c-0.017,0.132,0.021,0.266,0.104,0.371c0.082,0.105,0.203,0.172,0.336,0.188l3.34,0.382   c0.019,0.002,0.038,0.003,0.057,0.003c0.13,0,0.256-0.051,0.35-0.143c0.108-0.105,0.162-0.253,0.148-0.404L3.007,6.64   C2.944,5.976,3.322,5.362,3.948,5.114z"></path>
            <path d="M22.172,5.845c-0.025-0.202-0.17-0.368-0.366-0.421c-0.197-0.055-0.404,0.019-0.528,0.181   c-0.853,1.124-2.231,1.66-3.678,1.271c-0.171-0.044-0.356,0.002-0.483,0.129l-1.556,1.556c-0.566,0.566-1.555,0.566-2.121,0   C13.156,8.277,13,7.901,13,7.5s0.156-0.777,0.439-1.061l0.676-0.676c0.15-0.15,0.189-0.38,0.097-0.572   c-0.094-0.192-0.303-0.305-0.509-0.278l-9.262,1.09c-0.27,0.032-0.465,0.273-0.439,0.543l1.5,16   c0.013,0.134,0.079,0.257,0.183,0.341C5.774,22.961,5.886,23,6,23c0.019,0,0.038-0.001,0.057-0.003l17.5-2   c0.133-0.015,0.254-0.083,0.336-0.188c0.083-0.105,0.12-0.239,0.104-0.371L22.172,5.845z"></path>
            <path d="M14.146,7.146c-0.195,0.195-0.195,0.512,0,0.707C14.244,7.951,14.372,8,14.5,8s0.256-0.049,0.354-0.146l2.27-2.27   C17.519,5.846,17.991,6,18.5,6C19.879,6,21,4.878,21,3.5S19.879,1,18.5,1S16,2.122,16,3.5c0,0.509,0.154,0.981,0.416,1.377   L14.146,7.146z"></path>
        </g>
    </svg>
  )
}
