import React, { useState } from 'react'
import styles from './index.module.scss';
import WebLayout from '../../components/layout/web/index';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { authenticationLogin } from '../../../application/features/authentication';

export const LoginPage = () => {
    // hooks
    const navigate = useNavigate();

    // state
    const [payload, setPayload] = useState({});
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState();

    // redux
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.authentication.login.isLoading);
    const loginError = useSelector(state => state.authentication.login.error);
    const token = useSelector(state => state.authentication.token);

    // effects
    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token]);

    useEffect(() => {
        if (loginError) {
            setErrorMessage("Username and Password do not match");
            setTimeout(() => {
                setErrorMessage(null);
            }, 30000);
        }
    }, [loginError])

    // handlers
    const handleChange = (name, value) => {
        setPayload(prevState => {
            return {...prevState, [name]: value}
        });

        if (errors[name]) {
            errors[name] = null;
        }
    }

    const handleSubmit = () => {
        if (validate()) {
            dispatch(authenticationLogin(payload));
        }
    }

    // helpers
    const validate = () => {
        const _errors = {};
        let hasNoErrors = true;
        
        if (!payload.email) {
            hasNoErrors = false;
            _errors.email = "Email is required"
        }

        if (!payload.password) {
            hasNoErrors = false;
            _errors.password = "Password is required"
        }

        setErrors(_errors);
        return hasNoErrors;
    }
    return (
        <WebLayout>
            <div className={styles.wrapper}>
                <h1 className="my-5">Login</h1>
                {errorMessage && (
                    <div className='alert alert-danger mb-2'>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="" className="label-control">Email</label>
                    <input type="email" name="email" id="email" className={`form-control ${errors.email && 'is-invalid'}`} placeholder='Enter your email address.' onChange={(e) => handleChange("email", e.target.value)} />
                    {errors.email && <p className='invalid-feedback'>{errors.email}</p>}
                </div>
                <div className="form-group my-3">
                    <label htmlFor="" className="label-control">Password</label>
                    <input type="password" name="password" id="password" className={`form-control ${errors.password && 'is-invalid'}`} placeholder='Password' onChange={(e) => handleChange("password", e.target.value)} />
                    {errors.password && <p className='invalid-feedback'>{errors.password}</p>}
                </div>
                <div className="d-flex align-items-center">
                    <button className="btn btn-secondary" disabled={isLoading} onClick={handleSubmit}>Login {isLoading && <span className='spinner-border text-light'></span>}</button>
                    <button className="btn btn-outline-secondary mx-2" onClick={() => navigate("/register")}>Register</button>
                    <div className="flex-grow-1"></div>
                    {/* <p className="btn btn-link text-light">Forgot Password ?</p> */}
                </div>
            </div>
        </WebLayout>
    );
}
