import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authenticationRegister } from '../../../application/features/authentication';
import WebLayout from '../../components/layout/web/index';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const RegisterPage = () => {
    // hooks
    const navigate = useNavigate();

    // state
    const [payload, setPayload] = useState({});
    const [errors, setErrors] = useState({});

    // redux
    const dispatch = useDispatch();
    const isRegistering = useSelector(state => state.authentication.register.isLoading);
    const isRegistered = useSelector(state => state.authentication.register.isSuccessful);

    // effects
    useEffect(() => {
        if (isRegistered) {
            navigate("/");
        }
    }, [isRegistered]);

    // handlers
    const handleChange = (name, value) => {
        setPayload(prevState => {
            return {...prevState, [name]: value}
        });

        if (errors[name]) {
            errors[name] = null;
        }
    }

    const handleSubmit = () => {
        const hasErrors = validate();
        if (!hasErrors) {
            dispatch(authenticationRegister(payload));
        }
    }

    // helpers
    const validate = () => {
        let _errors = {};
        let hasErrors = false;
        
        if (!payload.name) {
            _errors.name = "Full name is required";
            hasErrors = true;
        }

        if (!payload.email) {
            _errors.email = "Email is required";
            hasErrors = true;
        }

        if (!payload.password) {
            _errors.password = "Password is required";
            hasErrors = true;
        }

        if (!payload.password_confirm) {
            _errors.password_confirm = "Confirm Password is required";
            hasErrors = true;
        }

        if (!hasErrors && (payload.password != payload.password_confirm)) {
            _errors.password_confirm = "Password Should Match";
            hasErrors = true;
        }

        if (hasErrors) {
            setErrors(_errors);
        }

        return hasErrors;
    }

    return (
        <WebLayout>
            <div className={styles.wrapper}>
                <h1 className="my-5">Register</h1>
                <div className="form-group mb-2">
                    <label htmlFor="" className="label-control">Full Name</label>
                    <input type="text" className={`form-control ${errors.name && 'is-invalid'}`} placeholder='Enter your full name.' onChange={(e) => handleChange("name", e.target.value)} />
                    {errors.name && <p className='invalid-feedback'>{errors.name}</p>}
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="" className="label-control">Email</label>
                    <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} placeholder='Enter your email address.' onChange={(e) => handleChange("email", e.target.value)} />
                    {errors.email && <p className='invalid-feedback'>{errors.email}</p>}
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="" className="label-control">Password</label>
                    <input type="password" className={`form-control ${errors.password && 'is-invalid'}`} placeholder='Enter your password.' onChange={(e) => handleChange("password", e.target.value)} />
                    {errors.password && <p className='invalid-feedback'>{errors.password}</p>}
                </div>

                <div className="form-group mb-2">
                    <label htmlFor="" className="label-control">Confirm Password</label>
                    <input type="password" className={`form-control ${errors.password_confirm && 'is-invalid'}`} placeholder='Confirm your password.' onChange={(e) => handleChange("password_confirm", e.target.value)} />
                    {errors.password_confirm && <p className='invalid-feedback'>{errors.password_confirm}</p>}
                </div>
                <div className="d-flex align-items-center">
                        <button className="btn btn-secondary" disabled={isRegistering} onClick={handleSubmit}>Register {isRegistered && <span className='spinner-border text-light'></span>}</button>
                        {/* <button className="btn btn-outline-secondary mx-2" onClick={() => navigate("/login")}>Register</button> */}
                        <div className="flex-grow-1"></div>
                        <p className="btn btn-link text-light text-xs" onClick={() => navigate("/login")}>Already Have an Account ?</p>
                    </div>
            </div>
        </WebLayout>
  )
}
