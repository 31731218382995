import React from 'react'
import WebLayout from '../../components/layout/web'
import { HomePageContactComponent } from '../home/components/contactForm';
import styles from './index.module.scss';

export const ContactPage = () => {
  return (
    <WebLayout>
        <div className={styles.contact_page}>
            <h1>Contact</h1>

            <HomePageContactComponent />
        </div>
    </WebLayout>
  )
}
