
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BeatZone from '../../api/beatzone';
import { customFetch } from '../../core/network';

// ACTION CONSTANTS
const BLOG_FETCH_POSTS = 'blog/FETCH_POSTS';
const BLOG_FETCH_CATEGORIES = 'blog/FETCH_CATEGORIES';

// ACTIONS
export const blogFetchPosts = createAsyncThunk(
    BLOG_FETCH_POSTS,
    async (data, store) => {
        return customFetch('GET', BeatZone.posts);
    }
)

// INITIAL STATE
const initialState = {
    categories: {
        isFetching: false,
        isFetchingSuccessful: false,
        fetchingError: null,
        data: [],
    },
    posts: {
        isFetching: false,
        isFetchingSuccessful: false,
        fetchingError: null,
        data: [],
    }
}

// SLICE
const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {},
    extraReducers: {
        [blogFetchPosts.pending]: (state, action) => {
            state.posts.isFetching = true;
            state.posts.isFetchingSuccessful = false;
            state.posts.fetchingError = null;
        },
        [blogFetchPosts.fulfilled]: (state, action) => {
            state.posts.isFetching = false;
            state.posts.isFetchingSuccessful = true;
            state.posts.data = action.payload.data;
        },
        [blogFetchPosts.rejected]: (state, action) => {
            state.posts.isFetching = false;
            state.posts.fetchingError = action.error;
        }
    },
});

export default blogSlice.reducer;