import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { cartHideLicenceModal } from '../../../application/features/cart';
import styles from './index.module.scss';
import { LicenceRow } from './licence';
import cover from '$assets/images/cover.jpeg';
import FeaturedBeatCover from '../../pages/home/components/featuredBeat/cover';
import { playerTogglePlay, playerUpdateSource } from '../../../application/features/player';

export const LicenceModalComponent = () => {
    // state
    const [ beat, setBeat ] = useState(null)
    
    // redux
    const dispatch = useDispatch();
    const licences = useSelector(state => state.licences.licences.data);
    const selectedBeat = useSelector(state => state.cart.selectedBeat);
    const beats = useSelector(state => state.beats.beats.data);
    const show = useSelector(state => state.cart.showLicenceModal);
    const currentSource = useSelector(state => state.player.source);

    // effects
    useEffect(() => {
        if (!selectedBeat) return;
        if (beats.length == 0) return;
        let _index = beats.findIndex(b => b.id == selectedBeat);
        if (_index == -1) return;
        let _beat = beats[_index];
        setBeat(_beat);
    }, [selectedBeat, beats])

    // handlers
    const handleClose = () => {
        dispatch(cartHideLicenceModal());
    }

    const handleTogglePlay = () => {
        console.log("Toggle play triggered from the modal component");
        if (currentSource != beat.media) {
            dispatch(playerUpdateSource(beat.media));
            return;
        }
        dispatch(playerTogglePlay());
    }

    return (
        <div className={`${styles.modal} ${!show && styles.hide}`}>
            <div className="d-flex">
                <h2 className='flex-grow-1'>Choose Licence Type</h2>
                <span onClick={handleClose}>X</span>
            </div>

            <div className={`${styles.content}`}>
                { beat && (
                    <div style={{ marginRight: 20, }}>
                        <FeaturedBeatCover size={180} cover={beat.cover} togglePlay={handleTogglePlay} featuredBeatOverride={beat} />
                        <h3 styles={styles.beatTitle}>{beat.name}</h3>
                    </div>
                )}
                <div className="flex-grow-1">
                    {licences.map(licence => <LicenceRow licence={licence} />)}
                </div>
            </div>
            
            
        </div>
    );
}
