import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CartItem } from '../../components/cartDropDown/item';
import WebLayout from '../../components/layout/web'
import styles from './index.module.scss';
import { CheckoutStripe } from './stripe'
import {useNavigate} from "react-router-dom";

export const CheckoutPage = () => {
    // hooks
    const navigate = useNavigate();
    const token = useSelector(state => state.authentication.token);

     // state
     const [items, setItems] = useState([]);
     const [total, setTotal] = useState(0);
     const [showStripeForm, setShowStripeForm] = useState(false);

     // redux
     const dispatch = useDispatch();
     const beats = useSelector(state => state.beats.beats.data);
     const licences = useSelector(state => state.licences.licences.data);
     const cartItems = useSelector(state => state.cart.items);
 
     // effects
     useEffect(() => {
        let _total = 0;
         let _items = cartItems.map(item => {
             let _beatIndex = beats.findIndex(b => b.id == item.beat_id);
             let _licenceIndex = licences.findIndex(l => l.id == item.licence_id);

             if (_licenceIndex > -1) {
                _total += parseFloat(licences[_licenceIndex].price);
              }
             return {
                 beat: beats[_beatIndex],
                 licence: licences[_licenceIndex],
             };
         });

         setTotal(_total);
         setItems(_items);
     }, [cartItems]);


  return (
    <WebLayout>
        <div className={`container text-light my-5 ${styles.checkout}`}>
            <h1>Cart Review</h1>
            
            <div className="row">
                <div className="col-lg-8">
                <table width="100%">
                <thead>
                    <th width="72px"></th>
                    <th>PRODUCT</th>
                    <th width="80px">PRICE</th>
                    <th className={styles.review} width="170px"></th>
                    <th width="10px"></th>
                </thead>

                <tbody>
                    {items.map(item => {
                        return (
                            <tr className={`my-2 ${styles.tableRow}`}>
                                <td>
                                    <img src={item.beat.cover} alt="" />
                                </td>
                                <td className={styles.flexCenter}>
                                    <h1>{item.beat.name} By Beatz ON</h1>
                                    <p>TRACK - {item.licence.name}</p>
                                </td>
                                <td className={styles.price}>
                                    <h3>${item.licence.price} </h3>
                                </td>
                                <td className={styles.review}>
                                    <button className="btn btn-dark">REVIEW LICENCE</button>
                                </td>
                                <td>
                                    <h1>X</h1>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
                </div>

                <div className="col-lg-4">
                    {showStripeForm ? (
                        <div className={styles.card}>
                            <CheckoutStripe />
                        </div>
                    ) : (
                        <div className={styles.card}>
                            <div className={styles.row}>
                                <div>Gross</div>
                                <span>${total}</span>
                            </div>
                            <div className={styles.row}>
                                <div>Discount</div>
                                <span>-$0.00</span>
                            </div>

                            <div className={styles.total}>
                                <div>Total</div>
                                <span>${total}</span>
                            </div>

                            {!token && <p className='text-center'>
                                Would you like to keep records of your transaction(s) for future download of your purchased files? <a onClick={() => navigate("/login")} className="text-light">Login</a>
                            </p>}

                            {/*<button className="btn btn-secondary form-control my-2">PAY VIA PAYPAL</button>*/}
                            <button className="btn btn-secondary form-control" onClick={() => setShowStripeForm(true)}>PAY VIA STRIPE</button>

                            <p className='text-center mt-3'>By clicking the button you accept the product(s) License Agreement(s)</p>
                            <p className='text-center'>Please read our Refund Policy.</p>
                        </div>
                    )}

                </div>
            </div>
        </div>
    </WebLayout>
  )
}
