import React from 'react'
import styles from './index.module.scss';
import { PropTypes } from 'prop-types';
import IconBag from '../../icons/bag';

const ButtonElevated = (props) => {
    const { icon: Icon, title, onClick } = props;
    
    return (
        <button onClick={onClick} className="btn btn-secondary">
            <div className="d-flex" style={{ alignItems: 'center', }}>
                {Icon && (
                    <div style={{ marginRight: 10 }}>
                        <Icon height="18px" width="18px" />
                    </div>
                )}
                <div className={styles.title}>
                    {title}
                </div>
            </div>
        </button>
    );
}

ButtonElevated.defaultProps = {
    title: "Button",
    icon: null,
}

ButtonElevated.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    onClick: PropTypes.func,
}

export default ButtonElevated;