import React from 'react'
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cartAddItem, cartHideLicenceModal } from '../../../application/features/cart';

export const LicenceRow = (props) => {
    //props
    const { licence } = props;

    // redux
    const dispatch = useDispatch();
    const selectedBeat = useSelector(state => state.cart.selectedBeat);

    // handlers
    const addToCart = () => {
        dispatch(cartAddItem({
            beat_id: selectedBeat,
            licence_id: licence.id,
        }));
        dispatch(cartHideLicenceModal());
    }

    return (
        <div className={styles.licenceRow}>
            <div className='flex-grow-1'>
                <h3>{licence.name}</h3>
                <p>{licence.tagline}</p>
            </div>
            <button className="btn btn-secondary" onClick={addToCart}>{licence.price} USD</button>
        </div>
    );
}
