import authentication from "./features/authentication";
import beats from "./features/beats";
import player from "./features/player";
import licences from "./features/licences";
import cart from "./features/cart";
import soundkits from "./features/soundkits";
import video from "./features/video";
import blog from "./features/blog";

export default {
    authentication,
    beats,
    player,
    licences,
    cart,
    soundkits,
    video,
    blog
}