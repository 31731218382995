import React from 'react'
import { PropTypes } from 'prop-types';
import { IconDownload } from '../../icons/download';
import styles from './index.module.scss';

const ButtonIcon = (props) => {
    const { icon: Icon } = props;
    return (
        <div className={styles.button}>
            <Icon height="18px" width="18px" />
        </div>
    );
}

ButtonIcon.defaultProps = {
    icon: IconDownload,
}

ButtonIcon.propTypes = {
    icon: PropTypes.element
}

export default ButtonIcon;