import React from 'react'
import styles from './index.module.scss';
import { PropTypes } from 'prop-types';

const ButtonPill = (props) => {
    const { title } = props;
    
    return (
        <div className={styles.button}>{title}</div>
    );
}

ButtonPill.defaultProps = {
    title: "pill chip"
}

ButtonPill.propTypes = {
    title: PropTypes.string,
}
export default ButtonPill;