import React, { useEffect, useState } from 'react'
import styles from './index.module.scss';
import { IconPlay } from '../../../../components/icons/play';
import ButtonCart from '../../../../components/buttons/cart/index';
import ButtonIcon from '../../../../components/buttons/icon';
import { IconShare } from '../../../../components/icons/share';
import ButtonPill from '../../../../components/buttons/pill';
import FeaturedBeatCover from './cover';
import AudioSpectrum from 'react-audio-spectrum';
import { useDispatch, useSelector } from 'react-redux';
import { playerTogglePlay, playerUpdateSource } from '../../../../../application/features/player';
import { cartSelectBeat, cartShowLicenceModal } from '../../../../../application/features/cart';
import IconBag from '../../../../components/icons/bag';
import ButtonElevated from '../../../../components/buttons/elevated/index';

const FeaturedBeat = (props) => {
    // state
    const [ inCart, setInCart ] = useState(false);

    // redux
    const dispatch = useDispatch();
    const currentSource = useSelector(state => state.player.source);
    const featuredBeat = useSelector(state => state.beats.featuredBeat);
    const cartItems = useSelector(state => state.cart.items);

    // effects
    useEffect(() => {
        if (cartItems.length == 0) {
            setInCart(false);
        }
        let _index = cartItems.findIndex(i => i.beat_id == featuredBeat.id);
        if (_index > -1) {
            setInCart(true);
        } else {
            setInCart(false);
        }
    }, [cartItems]);

    const handleTogglePlay = () => {
        if (!featuredBeat) return;
        
        if (currentSource != featuredBeat.media) {
            dispatch(playerUpdateSource(featuredBeat.media));
            return;
        }

        dispatch(playerTogglePlay());
    }

    if (!featuredBeat) {
        return <div></div>
    }

    const handleCart = () => {
        dispatch(cartSelectBeat(featuredBeat.id));
        dispatch(cartShowLicenceModal());
    }
    
    return (
        <div className={styles.wrapper}>
            <FeaturedBeatCover cover={featuredBeat.cover} togglePlay={handleTogglePlay} />

            <div className={styles.description}>
                <span>Featured Track • {featuredBeat.bpm}BPM</span>
                <h3>{featuredBeat.name}</h3>
                <div className={styles.actions}>
                    <ButtonElevated icon={ inCart ? null : IconBag } title={inCart ? "IN CART" : "30 USD" } onClick={handleCart} />

                    <ButtonIcon />
                    <ButtonIcon icon={IconShare} />

                    <ButtonPill title="#guiter afrobeat" />
                    <ButtonPill title="#drum hiphop" />
                </div>
            </div>
        </div>
    );
}

export default FeaturedBeat;