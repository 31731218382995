import React, { useEffect, useState } from 'react'
import styles from './index.module.scss';
import sample from '$assets/media/sample.mp3';
import cover_img from '$assets/images/cover.jpeg';
import FeaturedBeatCover from '../featuredBeat/cover';
import { PropTypes } from 'prop-types';
import ButtonPill from '../../../../components/buttons/pill';
import ButtonIcon from '../../../../components/buttons/icon/index';
import { IconDownload } from '../../../../components/icons/download';
import { IconShare } from '../../../../components/icons/share';
import ButtonCart from '../../../../components/buttons/cart';
import uuidv4 from "uuid/dist/v4";
import { useDispatch, useSelector } from 'react-redux';
import { playerPlay, playerTogglePlay, playerUpdateSource } from '../../../../../application/features/player';
import { cartSelectBeat, cartShowLicenceModal } from '../../../../../application/features/cart';
import ButtonElevated from '../../../../components/buttons/elevated';
import IconBag from '../../../../components/icons/bag';
import {IconPlay} from "../../../../components/icons/play";
import {IconPause} from "../../../../components/icons/pause";

const BeatRow = (props) => {
    //props
    const { beatId, title, cover, time, bpm, tags, media_url } = props;

    // state
    const [inCart, setInCart ] = useState(false);

    //redux
    const dispatch = useDispatch();
    const currentSource = useSelector(state => state.player.source);
    const isPlaying = useSelector(state => state.player.isPlaying);
    const cartItems = useSelector(state => state.cart.items);

    // effects
    useEffect(() => {
        if (cartItems.length == 0) {
            setInCart(false);
        }

        let _index = cartItems.findIndex(i => i.beat_id == beatId);
        if (_index > -1) {
            setInCart(true);
        } else {
            setInCart(false);
        }
    }, [cartItems])
    
    //handlers
    const handleTogglePlay = () => {
        console.log("Beat Row PLay Toggled from the Cover Component");
        if (currentSource != media_url) {
            dispatch(playerUpdateSource(media_url));
            return;
        }
        dispatch(playerTogglePlay());
    }

    const handleCart = () => {
        dispatch(cartSelectBeat(beatId));
        dispatch(cartShowLicenceModal());
    }
    
    return (
        <div key={uuidv4()} className={`${styles.beatrow} ${isPlaying && (currentSource == media_url) ? styles.active : null}`}>
            <div className={styles.cover} onClick={handleTogglePlay}>
                <img src={cover} alt="" />
                <div className={styles.play}>
                    {!(isPlaying && (media_url == currentSource)) ? <IconPlay height="12px" width="12px" /> : <IconPause height="12px" width="12px" />}
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.displayMedium}>
                    <div className={styles.time}>
                        {time}
                    </div>
                </div>
                <div className={styles.displayMedium}>
                    <div className={styles.bpm}>
                        {bpm}
                    </div>
                </div>
                
                <div className={styles.tags}>
                    {tags.map(tag => <ButtonPill title={`#${tag}`} />)}
                </div>
                
                <div className={styles.actions}>
                    <ButtonIcon icon={IconDownload} />
                    <ButtonIcon icon={IconShare} />
                    <div className={styles.cart}>
                        <ButtonElevated icon={ inCart ? null : IconBag } title={inCart ? "IN CART" : "30 USD" } onClick={handleCart} />
                    </div>
                </div>
            </div>
        </div>
    );
}

BeatRow.defaultProps = {
    title: "MUCHOS",
    cover: cover_img,
    time: "03:35",
    bpm: "96",
    tags: ["jazz", "hiphop"],
}

BeatRow.propTypes = {
    title: PropTypes.string,
    cover: PropTypes.any,
    time: PropTypes.string,
    bpm: PropTypes.string,
    tags: PropTypes.array,
    media_url: PropTypes.string,
}
export default BeatRow;
