
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BeatZone from '../../api/beatzone';
import { customFetch } from '../../core/network';

// ACTION CONSTANTS
const SOUNDKIT_FETCH_SOUNDKITS = `soundkit/FETCH_SONDKITS`;

// ACTIONS
export const soundkitFetchSoundkits = createAsyncThunk(
    SOUNDKIT_FETCH_SOUNDKITS,
    async (data, store) => {
        return await customFetch('GET', BeatZone.soundkits);
    }
);

// INITIAL STATE
const initialState = {
    soundkits: {
        isFetching: false,
        isFetchingSuccessful: false,
        fetchingError: null,
        data: [],
    }
}

// SLICE
const soundKitSlice = createSlice({
    name: "soundKit",
    initialState,
    reducers: {},
    extraReducers: {
        [soundkitFetchSoundkits.pending]: (state, action) => {
            state.soundkits.isFetching = true;
            state.soundkits.isFetchingSuccessful = false;
            state.soundkits.fetchingError = null;
        },
        [soundkitFetchSoundkits.fulfilled]: (state, action) => {
            state.soundkits.isFetching = false;
            state.soundkits.isFetchingSuccessful = true;
            state.soundkits.data = action.payload.data;
        },
        [soundkitFetchSoundkits.rejected]: (state, action) => {
            state.soundkits.isFetching = false;
            state.soundkits.fetchingError = action.error;
        },
    },
});

export default soundKitSlice.reducer;