import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BeatZone from "../../api/beatzone";
import { customFetch } from "../../core/network";

// ACTION CONSTANTS
const BEAT_FETCH_BEATS = 'beat/fetch-beats';

// ACTIONS
export const beatFetchBeats = createAsyncThunk(
    BEAT_FETCH_BEATS,
    async (data, store) => {
        return await customFetch('GET', BeatZone.beats);
    }
);

const initialState = {
    beats: {
        isFetching: false,
        isFetchingSuccessful: false,
        fetchingError: null,
        data: []
    },
    featuredBeat: null,
}

const beatSlice = createSlice({
    name: "beats",
    initialState,
    reducers: {
        beatSetFeaturedBeat: (state, action) => {
            state.featuredBeat = action.payload
        }
    },
    extraReducers: {
        [beatFetchBeats.pending]: (state, action) => {
            state.beats.isFetching = true;
            state.beats.isFetchingSuccessful = false;
            state.beats.fetchingError = null;
        },
        [beatFetchBeats.fulfilled]: (state, action) => {
            state.beats.isFetching = false;
            state.beats.isFetchingSuccessful = true;
            if (action.payload.data.length) {
                state.beats.data = action.payload.data;
                state.featuredBeat = action.payload.data[0];
            }
        },
        [beatFetchBeats.rejected]: (state, action) => {
            state.beats.isFetching = false;
            state.beats.fetchingError = action.error;
        }
    },
});

export default beatSlice.reducer;