import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { soundkitFetchSoundkits } from "../../../application/features/soundkits";
import WebLayout from "../../components/layout/web";
import HomePageSoundKit from "../home/components/soundkit";

export const SoundKitsPage = () => {

    //redux
    const dispatch = useDispatch();
    const soundkits = useSelector(state => state.soundkits.soundkits.data);

    useEffect(() => {
        if (soundkits.length > 0) return;
        dispatch(soundkitFetchSoundkits());
    }, []);
    
    return (
        <WebLayout>
            <div className="container">
                <div className="row">
                    <h1 className='text-light text-center my-5'>Sound Kits</h1>
                </div>
                <div className="row">
                    {soundkits.map((kit) => {
                        return (
                            <div className="col-lg-4">
                                <HomePageSoundKit soundkit={kit} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </WebLayout>
    );
};
