import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { playerPause, playerPlay, playerTogglePlay, playerUpdateSource } from '../../../application/features/player';
import sample from '$assets/media/sample.mp3';
import styles from './index.module.scss';
import { IconPrevious } from '../icons/previous';
import { IconPlay } from '../icons/play';
import { IconNext } from '../icons/next';
import { IconPause } from '../icons/pause';

const AudioPlayer = () => {
    // refs
    const audioPlayerRef = useRef(null);

    // state
    const [currentBeat, setCurrentBeat] = useState(null);
    const [volume, setVolume] = useState(0);

    // redux
    const dispatch = useDispatch();
    const isPlaying = useSelector(state => state.player.isPlaying);
    const source = useSelector(state => state.player.source);
    const beats = useSelector(state => state.beats.beats.data);

    // effects
    useEffect(() => {
      if (!audioPlayerRef.current) return;

      const audio = audioPlayerRef.current

      setVolume(audio.volume * 100);

      audio.addEventListener('ended', () => handleEventEnded());
      audio.addEventListener('pause', () => handleEventPause());
      audio.addEventListener('play', () => handleEventPlay());
      audio.addEventListener('playing', () => handleEventPlaying());
      audio.addEventListener('loadeddata', () => handleEventLoadedData());
      audio.addEventListener('loadstart', () => handleEventLoadStart());
      
      
      return () => {
        audio.addEventListener('ended', () => handleEventEnded());
        audio.addEventListener('pause', () => handleEventPause());
        audio.addEventListener('play', () => handleEventPlay());
        audio.addEventListener('playing', () => handleEventPlaying());
        audio.addEventListener('loadeddata', () => handleEventLoadedData());
        audio.addEventListener('loadstart', () => handleEventLoadStart());
      };
    }, [audioPlayerRef]);

    useEffect(() => {
      if (!audioPlayerRef.current) return;
      if (!source) return;
      const audio = audioPlayerRef.current
      audio.crossorigin = "anonymous"
      audio.src = source;
      audio.load();
    }, [source, audioPlayerRef]);
  
    useEffect(() => {
      if (!audioPlayerRef.current) return;
      const audio = audioPlayerRef.current
      if (isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
    }, [isPlaying, audioPlayerRef]);

    useEffect(() => {
      if (!audioPlayerRef.current) return;
      const audio = audioPlayerRef.current
      audio.volume = 1 * volume/100;
    }, [audioPlayerRef, volume])

    useEffect(() => {
      if (!source) return;
      if (beats.length == 0) return;

      const index = beats.findIndex(beat => beat.media == source);
      if (index != -1) {
        setCurrentBeat(beats[index]);
      }
      // udpate current beat
    }, [source])

    //Event Handlers
    const handleEventEnded = () => {
      console.debug("AUDIO EVENT: ended");
      dispatch(playerPause());
    }

    const handleEventPause = () => {
      console.debug("AUDIO EVENT: pause");
      dispatch(playerPause());
    }

    const handleEventPlay = () => {
      console.debug("AUDIO EVENT: play");
      dispatch(playerPlay());
    }

    const handleEventPlaying = () => {
      console.debug("AUDIO EVENT: playing");
      dispatch(playerPlay())
    }

    const handleEventLoadedData = () => {
      console.debug("AUDIO EVENT: loadeddata");
      dispatch(playerPlay())
    }

    const handleEventLoadStart = () => {
      console.debug("AUDIO EVENT: loadstart");
      dispatch(playerPause())
    }

    const handleVolumeChange = (e) => {
      let value = e.target.value;
      setVolume(value);
    }

    const handleNext = () => {
      const index = beats.findIndex(beat => beat.media == source);
      if (index < beats.length - 1) {
        dispatch(playerUpdateSource(beats[index + 1].media));
      } else {
        dispatch(playerUpdateSource(beats[0].media));
      }
    }

    const handlePrev = () => {
      const index = beats.findIndex(beat => beat.media == source);
      if (index > 0) {
        dispatch(playerUpdateSource(beats[index - 1].media));
      } else {
        dispatch(playerUpdateSource(beats[beats.length - 1].media));
      }
    }

    return (
      <div>
        <audio ref={audioPlayerRef} id="audio-player" crossOrigin="anonymous"></audio>
        {currentBeat && (
          <div className={styles.bottomPlayer}>
            <div className={styles.seekbar}></div>
            <div className={styles.content}>
              <div className={styles.info}>
                <img src={currentBeat.cover} alt="" />
                <div className={styles.description}>
                  <h2>{currentBeat.name}</h2>
                  <p>Beatz ON</p>
                </div>
              </div>
              <div className={styles.controls}>
                <IconPrevious height="20px" width="20px" onClick={handlePrev} />
                {isPlaying ? <IconPause height="20px" width="20px" onClick={() => dispatch(playerPause())} /> : <IconPlay height="20px" width="20px" onClick={() => dispatch(playerPlay())}  />}
                <IconNext height="20px" width="20px" onClick={handleNext} />
              </div>
              <div className={styles.actions}>
                <div className={styles.volumeSlider}>
                  <input type="range" min="1" max="100" defaultValue={volume} onChange={handleVolumeChange} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}

export default AudioPlayer;