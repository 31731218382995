import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import WebLayout from '../../components/layout/web';

export const BlogShow = (props) => {
    // hooks
    const { id } = useParams();

    // state
    const [post, setPost] = useState(null);

    // redux
    const posts = useSelector(state => state.blog.posts.data);

    // effects
    useEffect(() => {
        if (!id) return;
        if (!posts.length) return;
        const _post = posts.find(p => p.id == id);
        if (_post) {
            setPost(_post);
        }
    }, [id, posts]);

    if (!post) {
        return <p>Post Not Found</p>
    }

    return (
        <WebLayout>
            <div className="container text-light">
            <div className="row">
                    <h1 className='text-light text-center my-5'>Blog Post</h1>
                </div>
                <div className="row">
                    <div className="col">
                        <img src={post.featured_image} alt="" height="200px" />

                        <h1>{post.title}</h1>

                        <p>{post.post}</p>
                    </div>
                </div>
            </div>
        </WebLayout>
    );
}
