import React from 'react'

export const IconHome = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" viewBox="0 0 20 20" fill="currentColor" {...props}>
        <g>
            <rect fill="none" height="20" width="20"></rect>
        </g>
        <g>
            <g>
                <path d="M15,8.29V5.5C15,5.22,14.78,5,14.5,5h-1C13.22,5,13,5.22,13,5.5v1.07l-2.67-2.29c-0.19-0.16-0.46-0.16-0.65,0L3,10h2v5.5 C5,15.78,5.22,16,5.5,16h3C8.78,16,9,15.78,9,15.5V12h2v3.5c0,0.28,0.22,0.5,0.5,0.5h3c0.28,0,0.5-0.22,0.5-0.5V10h2L15,8.29z M9,9c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1H9z"></path>
            </g>
        </g>
    </svg>
  )
}
