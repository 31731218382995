import React from 'react'
import styles from './index.module.scss';

const BeatRowHeader = (props) => {
    return (
        <div className={styles.beatrow}>
            <div className={styles.cover}>
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    TITLE
                </div>
                <div className={styles.displayMedium}>
                    <div className={styles.time}>
                        TIME
                    </div>
                </div>
                <div className={styles.displayMedium}>
                    <div className={styles.bpm}>
                        BPM
                    </div>
                </div>
                
                <div className={styles.tags}>
                    TAGS
                </div>
                
                <div className={styles.actions}>
                    
                </div>
            </div>
        </div>
    );
}

export default BeatRowHeader;