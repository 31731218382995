
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import BeatZone from '../../api/beatzone';
import { customFetch } from '../../core/network';

// ACTION CONSTANTS
const LICENCE_FETCH_LICENCES = 'licence/FETCH_LICENCES';

// ACTIONS
export const licenceFetchLicences = createAsyncThunk(
    LICENCE_FETCH_LICENCES,
    async (params, store) => {
        return await customFetch('GET', BeatZone.licences);
    },
)

const initialState = {
    licences: {
        isFetching: false,
        isFetchingSuccessful: false,
        fetchingError: null,
        data: []
    },
    featuredBeat: null,
}

const licenceSlice = createSlice({
    name: "licences",
    initialState,
    reducers: {},
    extraReducers: {
        [licenceFetchLicences.pending]: (state, action) => {
            state.licences.isFetching = true;
            state.licences.isFetchingSuccessful = false;
            state.licences.fetchingError = null;
        },
        [licenceFetchLicences.fulfilled]: (state, action) => {
            state.licences.isFetching = false;
            state.licences.isFetchingSuccessful = true;
            state.licences.data = action.payload.data;
        },
        [licenceFetchLicences.rejected]: (state, action) => {
            state.licences.isFetching = false;
            state.licences.isFetchingSuccessful = false;
            state.licences.fetchingError = action.error;
        }
    },
});

export default licenceSlice.reducer;