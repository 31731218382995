import React from 'react'
import WebLayout from '../../components/layout/web'
import mixer from '$assets/images/mixer.jpeg';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { MixAndMasterDetailsPage } from './details';

export const MixAndMasterPage = () => {

    // hooks
    const navigate = useNavigate();

    return (
        <WebLayout>
            <div className="container">
                <div className="row">
                    <h1 className='text-light text-center my-5'>Mix & Master</h1>
                </div>

                <div className="row">
                    <div className="col-lg-4 text-light text-center">
                        <img src={mixer} alt="" width="100%" />
                        <h3 className='mt-2'>Mixing and Mastering</h3>
                        <h6>300 $</h6>
                        <button onClick={() => navigate("details")} className="btn btn-secondary">DETAILS</button>
                    </div>
                </div>
            </div>
        </WebLayout>
    );
}

