import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import WebLayout from '../../components/layout/web';
import styles from './index.module.scss';
import { useEffect } from 'react';
import { videoFetchVideos } from '../../../application/features/video';

export const TutorialsPage = () => {
    // redux
    const dispatch = useDispatch();
    const videos = useSelector(state => state.video.videos.data);

    // effects
    useEffect(() => {
      if (videos.length > 0) return;
      dispatch(videoFetchVideos());
    }, []);

    return (
      <WebLayout>
          <div className="container">
            <div className="row">
                    <h1 className='text-light text-center my-5'>Videos</h1>
                </div>
            <div className="row">
              {videos.map(video => {
                return (
                  <div className='col-lg-3'>
                    <img src={video.cover} alt="" />
                  </div>
                )
              })}
            </div>
          </div>
      </WebLayout>
    );
}
