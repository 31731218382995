import React from 'react'
import AudioSpectrum from 'react-audio-spectrum';
import beat from '$assets/media/sample.mp3';
import { useSelector } from 'react-redux';

const AudioVisualizer = (props) => {
  const isPlaying = useSelector(state => state.player.isPlaying);

  return (
    <div className="">
        <AudioSpectrum
            id="audio-canvas"
            height={isPlaying ? 100 : 0}
            width={1080}
            audioId={'audio-player'}
            capColor={'red'}
            capHeight={2}
            meterWidth={2}
            meterCount={512}
            meterColor={[
                {stop: 0, color: '#f00'},
                {stop: 0.5, color: '#0CD7FD'},
                {stop: 1, color: 'red'}
            ]}
            gap={4}
        />
    </div>
  )
}

export default AudioVisualizer;