import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPlaying: false,
    source: null,
}

const playerSlice = createSlice({
    name: "player",
    initialState,
    reducers: {
        playerPlay: (state, action) => {
            state.isPlaying = true;
        },
        playerPause: (state, action) => {
            state.isPlaying = false;
        },
        playerTogglePlay: (state, action) => {
            state.isPlaying = !state.isPlaying;
        },
        playerUpdateSource: (state, action) => {
            state.source = action.payload;
        }
    },
    extraReducers: {},
});

export default playerSlice.reducer;
export const { playerTogglePlay, playerUpdateSource, playerPlay, playerPause } = playerSlice.actions;