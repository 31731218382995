export const customFetch = async (method, endpoint, data, token, thunkApi) => {
    
    var url = new URL(endpoint);

    if (method == 'GET' && data) {
        url.search = new URLSearchParams(data).toString();
    }

    if ((method == 'POST' || method == 'PUT') && data) {
        var formData = jsonToFormData(data);
    } else {
        var formData = new FormData();
    }
    
    try {
        console.log(url, method, token, data, formData);
        const response = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Accept': 'application/json',
                'Authorization': token ? ('Bearer ' + token) : null,
                'Access-Control-Allow-Origin': "*",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: ((method == 'POST' || method == 'PUT') && data) ? formData : null, // body data type must match "Content-Type" header
        });

        if (response.status == 204) { // Special Case
            return response.body;
        }
    
        const results = await response.json();
        console.log(results);
        if (response.status >= 200 && response.status < 300) {
            console.log("HTTP RESPONSE: ", results);
            return results;
        }
    
        /**
         * If the Response is Unauthenticated then refresh token or redirect to Login
         */
        if(response.status == 401) {
            throw 'UNAUTHORIZED';
        }
    
    
        if (response.status == 422) {
            if(thunkApi != null) {
                return thunkApi.rejectWithValue(results);
            } else {
                throw JSON.stringify(results);
            }
        }
    
        //error handling
        if (response.status == 500) {
            throw "Server Error";
        }
    
        console.error(results);
    
        if (results.message) {
            throw results.message;
        }
    
        if (results.data && results.data.message) {
            throw results.data.message;
        }
        throw "Something went wrong";
    } catch (e) {
        console.error(e);
        throw e;
    }
}

// Converting json to FormData
function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}
function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}