import React, { useEffect } from 'react'
import WebLayout from '../../components/layout/web/index';
import banner from '$assets/images/daressalaam.jpeg';
import styles from './index.module.scss';
import logo from '$assets/images/beatzone-logo-border.gif';
import FeaturedBeat from './components/featuredBeat';
import BeatRow from './components/beatrow';
import ButtonElevated from '../../components/buttons/elevated';
import sample from '$assets/images/cover.jpeg';
import AudioVisualizer from '../../components/visualizer';
import BeatRowHeader from './components/beatrow/header';
import { useDispatch, useSelector } from 'react-redux';
// import ButtonElevated from '../../components/buttons/elevated';
import { beatFetchBeats } from '../../../application/features/beats';
import { licenceFetchLicences } from '../../../application/features/licences';
import { HomePageContactComponent } from './components/contactForm';
import { soundkitFetchSoundkits } from '../../../application/features/soundkits';
import HomePageSoundKit from './components/soundkit';

const HomePage = () => {

    // redux
    const dispatch = useDispatch();
    const beats = useSelector(state => state.beats.beats.data);
    const licences = useSelector(state => state.licences.licences.data);
    const soundkits = useSelector(state => state.soundkits.soundkits.data);

    // effects
    useEffect(() => {
        dispatch(beatFetchBeats());
        dispatch(licenceFetchLicences());
        dispatch(soundkitFetchSoundkits());
    }, []);

    return (
        <WebLayout>
            <div>
                <div className={styles.hero}>
                    <img className={styles.banner} src={banner} alt="" />

                    <div className={styles.content}>
                        <img src={logo} alt="" height="200px" />

                        <div className={styles.search}>
                            <input type="text" placeholder='What type of track are you looking for ?' />
                            <button>SEARCH</button>
                        </div>

                        <div className={styles.featuredBeat}>
                            <FeaturedBeat />
                        </div>
                    </div>
                </div>


                <div className={styles.playlist}>
                    <div className="">
                        <AudioVisualizer />
                    </div>

                    <BeatRowHeader />
                    {beats.map(beat => <BeatRow beatId={beat.id} title={beat.name} cover={beat.cover} time={beat.duration} bpm={beat.bpm} tags={beat.tags.split(",")} media_url={beat.media} />)}

                    <div className={styles.button}>
                        <ButtonElevated title={`BROWSE ALL TRACKS`} />
                    </div>
                </div>

                <div className={styles.licensing}>
                    <div className={styles.wrapper}>
                        {licences.map((licence, i) => {
                            return (
                                <div className={`${styles.item} ${i == 2 && styles.popular}`}>
                                    <h5>{licence.name}</h5>
                                    {licence.price ? <h1>${licence.price}</h1> : <h3 className='text-center'>MAKE AN OFFER</h3>}
                                    {licence.tagline && <h6 className='text-center'>{licence.tagline}</h6>}

                                    {licence.pros.length > 1 ? (
                                        <ul>
                                            {licence.pros.map(pro => <li>{pro}</li>)}
                                        </ul>
                                    ): null}

                                    <ButtonElevated title={`BROWSE ALL TRACKS`} />

                                    <div className={`${styles.bulkdeals} text-center mt-3`}>
                                        <p className='m-0 p-0'>Bulk Details:</p>
                                        <h6>BUY 2, GET 1 FREE</h6>
                                        <h6>BUY 4, GET 2 FREE</h6>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={styles.soundkits}>
                    <div className={styles.wrapper}>
                        {soundkits.map((kit) => {
                            return (
                                <HomePageSoundKit soundkit={kit}  />
                            );
                        })}
                    </div>

                    <ButtonElevated title="BROWSE ALL SOUND KITS" />
                </div>

                <div className={styles.services}>
                    <h2>Services</h2>

                    <div className="">
                        <div className="">
                            <img src={sample} alt="" />
                            <h4>Mixing and Mastering</h4>
                            <p>Get your Song Proffesional Mixed</p>
                        </div>
                    </div>

                    <ButtonElevated title="BROWSE ALL SERVICES" />
                </div>

                <div className={styles.subscription}>
                    <h2 className='text-center'>Subscribe to Mailing List</h2>
                    <div className='my-2'>
                        <input className='form-control' type="text" placeholder='Full name' />
                    </div>

                    <div>
                        <input className='form-control' type="text" placeholder='Email Address' />
                    </div>
                    
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" for="flexCheckDefault">
                            Check this box to confirm that you would like to receive emails from Beat Zone.
                        </label>
                    </div>

                    <div className="d-grid mt-3">
                        <button className="btn btn-secondary d-block">SUBSCRIBE NOW</button>
                    </div>
                </div>

                <div className={styles.contact}>
                    <h2 className='text-center'>Contact</h2>
                    <HomePageContactComponent />
                </div>
            </div>
        </WebLayout>
    )
}

export default HomePage;