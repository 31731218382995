import React, { useRef, useState } from 'react'
import IconBag from '../../icons/bag';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { LicenceRow } from '../../licenceModal/licence';
import { useSelector } from 'react-redux';


const ButtonCart = (props) => {
    // props
    const { price, onClick } = props;

    // state
    const [show, setShow] = useState(false);

    //hanldle Click
    const handleClick = () => {
        console.log("Add to cart button clicked");
        if (!onClick) return;
        onClick();
    }

    return (
        <div className={styles.csbtn}>
            <div className={styles.flex} onClick={handleClick}>
                <IconBag height="18px" width="18px" />
                <span>{price} TZS</span>
            </div>
        </div>
    );
}

ButtonCart.defaultProps = {
    price: 30,
}

ButtonCart.propTypes = {
    price: PropTypes.number,
    beat_id: PropTypes.number,
}

export default ButtonCart;