class BeatZone {
    static base_url = process.env.ENV == 'development' ? process.env.BEATZONE_API_DEV_URL : process.env.BEATZONE_API_PROD_URL
    // static base_url = "https://api.beatzon.com/api";
    
    static login = `${this.base_url}/login`;
    static register = `${this.base_url}/register`;
    
    static beats = `${this.base_url}/beats`;
    static licences = `${this.base_url}/licences`;
    static soundkits = `${this.base_url}/soundKits`;
    static videos = `${this.base_url}/videos`;
    static posts = `${this.base_url}/blog/posts`;

    static stripe_payment_intent = `${this.base_url}/stripe/payment-intent`;
}

export default BeatZone