import React, { useEffect, useState } from 'react'
import logo from '$assets/images/beatzone-logo.gif';
import styles from './index.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import IconBag from '../../icons/bag';
import { useDispatch, useSelector } from 'react-redux';
import { playerTogglePlay } from '../../../../application/features/player';
import { CartDropDownComponent } from '../../cartDropDown';
import { IconMenu } from '../../icons/menu';
import useWindowDimensions from '../../../hooks/windowDimensions';
import { IconHome } from '../../icons/home';
import { IconBeat } from '../../icons/beat';
import { IconWav } from '../../icons/wav';
import { IconEmail } from '../../icons/email';
import { IconAddress } from '../../icons/address';
import { IconVideos } from '../../icons/videos';
import { IconMixer } from '../../icons/mixer';
import { IconPost } from '../../icons/post';
import { IconUser } from '../../icons/user';
import { authenticationLogout } from '../../../../application/features/authentication';

const WebLayout = (props) => {
  // props
  const { children } = props;

  // state
  const [showCart, setShowCart ] = useState(false);
  const [total, setTotal] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  // hooks
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();
  const token = useSelector(state => state.authentication.token);
  const items = useSelector(state => state.cart.items);
  const licences = useSelector(state => state.licences.licences.data);

  // effect
  useEffect(() => {
    let _total = 0;
    items.forEach(item => {
      let index = licences.findIndex(l => l.id == item.licence_id);
      if (index > -1) {
        _total += parseFloat(licences[index].price);
      }
    });
    setTotal(_total);
  }, [items]);

  // handlers
  const toggleCart = (e) => {
    setShowCart(!showCart);
  }

  const handleNavigate = (endpoint) => {
    navigate(endpoint);
    setShowMenu(false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <div className={styles.toggleMenu}>
          <IconMenu height="45" color="white" onClick={() => setShowMenu(prevState => !prevState)} />
        </div>
        <img src={logo} alt="" onClick={() => navigate("/")} />

        <div className={`${styles.mainmenu}`}>
          <NavLink className={isActive => isActive.isActive ? styles.active : null} exact to="/tutorials">Tutorials</NavLink>
          <NavLink className={isActive => isActive.isActive ? styles.active : null} exact to="/beats">Beats</NavLink>
          <NavLink className={isActive => isActive.isActive ? styles.active : null} exact to="/soundkits">Sound Kits</NavLink>
          <NavLink className={isActive => isActive.isActive ? styles.active : null} exact to="/mix-and-master">Mix & Master</NavLink>
          <NavLink className={isActive => isActive.isActive ? styles.active : null} exact to="/blog">News & Blog</NavLink>
          <NavLink className={isActive => isActive.isActive ? styles.active : null} exact to="/contact">Contact</NavLink>
        </div>

        <div className={styles.iconbtn} onClick={toggleCart}>
          <IconBag height="20px" width="20px" fill='white' />
          <span>{total.toFixed(2)} TZS</span>
          {showCart && (
            <div className={styles.dropdown}>
              <CartDropDownComponent />
            </div>
          )}
        </div>

        {token ? (
          <div className={styles.iconbtn} onClick={() => dispatch(authenticationLogout())}>
            <IconUser height="20px" width="20px" fill='white' />
            <span>Logout</span>
          </div>
        ) : (
          <div className={styles.iconbtn} onClick={() => handleNavigate("/login")}>
            <IconUser height="20px" width="20px" fill='white' />
            <span>Login</span>
          </div>
        )}
      </div>
      <div className={`${styles.mobileMenu} ${showMenu ? styles.show : null}`} style={{ height: (height - 55) }}>
        <div className={styles.mobileMenuWrapper}>
          <div className={styles.mobileMenuItem} onClick={() => handleNavigate("/")}>
            <IconHome width="30" color="white" />
            <span>Home</span>
          </div>
          <div className={styles.mobileMenuItem} onClick={() => handleNavigate("/tutorials")}>
            <IconVideos width="30" color="white" />
            <span>Tutorials</span>
          </div>
          <div className={styles.mobileMenuItem}  onClick={() => handleNavigate("/beats")}>
            <IconBeat width="30" color="white" />
            <span>Beats</span>
          </div>
          <div className={styles.mobileMenuItem}  onClick={() => handleNavigate("/soundkits")}>
            <IconWav width="30" color="white" />
            <span>Sound Kits</span>
          </div>
          <div className={styles.mobileMenuItem}  onClick={() => handleNavigate("/mix-and-master")}>
            <IconMixer width="30" color="white" />
            <span>Mix & Master</span>
          </div>
          <div className={styles.mobileMenuItem}  onClick={() => handleNavigate("/blog")}>
            <IconPost width="30" color="white" />
            <span>Blog</span>
          </div>
          <div className={styles.mobileMenuItem}  onClick={() => handleNavigate("/contact")}>
            <IconAddress width="30" color="white" />
            <span>Contact</span>
          </div>
          {/* <div className={styles.mobileMenuItem}  onClick={() => handleNavigate("/contact")}>
            <IconUser width="30" color="white" />
            <span>Login</span>
          </div> */}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <div className={styles.logo}>
          BEAT ZONE
        </div>
        
        <div>
          <ul>
            <li>Home</li>
            <li>Beats</li>
            <li>Sound Kits</li>
          </ul>
        </div>

        <div>
          <ul>
            <li>Mix & Masters</li>
            <li>Discography</li>
            <li>Contact</li>
          </ul>
        </div>

        <div>
          <ul>
            <li>Licensing Info</li>
            <li>Terms of Use</li>
            <li>Privacy Policy</li>
          </ul>
        </div>

        <div>
          
        </div>
      </div>
    </div>
  )
}

export default WebLayout;