import React, { useEffect, useState } from 'react'
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CartItem } from './item';
import { useNavigate } from 'react-router-dom';

export const CartDropDownComponent = () => {
    // hooks
    const navigate = useNavigate();

    // state
    const [items, setItems] = useState([]);

    // redux
    const dispatch = useDispatch();
    const beats = useSelector(state => state.beats.beats.data);
    const licences = useSelector(state => state.licences.licences.data);
    const cartItems = useSelector(state => state.cart.items);

    // effects
    useEffect(() => {
        let _items = cartItems.map(item => {
            let _beatIndex = beats.findIndex(b => b.id == item.beat_id);
            let _licenceIndex = licences.findIndex(l => l.id == item.licence_id);
            return {
                beat: beats[_beatIndex],
                licence: licences[_licenceIndex],
            };
        });

        setItems(_items);
    }, [cartItems]);

    // handlers
    const handleNavigate = () => {
        navigate("/checkout");
    }

    return (
        <div className={styles.dropdown}>
            <h5>Your Cart ({cartItems.length})</h5>
            
            <div>
                {items.map(item => <CartItem beat={item.beat} licence={item.licence} />)}
            </div>

            <div>
                <button className="btn btn-secondary d-block mt-4" onClick={handleNavigate}>PROCEED TO CHECKOUT</button>
            </div>
        </div>
    );
}
