import React from 'react'

export const IconPause = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor" {...props}>
        <path d="M12 38h8V10h-8v28zm16-28v28h8V10h-8z"></path>
        <path d="M0 0h48v48H0z" fill="none"></path>
    </svg>
  )
}
