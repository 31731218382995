import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BeatZone from "../../api/beatzone";
import { customFetch } from "../../core/network";
import { authenticationLogin } from './authentication';

// ACTION CONSTANTS
const AUTHENTICATION_LOGIN = `authentication/LOGIN`;
const AUTHENTICATION_REGISTER = `authentication/REGISTER`;

// ACTIONS
export const authenticationLogin = createAsyncThunk(
    AUTHENTICATION_LOGIN,
    async (data, store) => {
        return await customFetch('POST', BeatZone.login, data);
    }
)

export const authenticationRegister = createAsyncThunk(
    AUTHENTICATION_REGISTER,
    async (data, store) => {
        return await customFetch('POST', BeatZone.register, data);
    }
)

// INITIAL STATE
const initialState = {
    token: null,
    user: null,
    login: {
        isLoading: false,
        isSuccessful: false,
        data: null,
        error: null,
    },
    register: {
        isLoading: false,
        isSuccessful: false,
        data: null,
        error: null,
    }
}

// SLICE
const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        authenticationLogout: (state, action) => {
            state.token = null;
            state.user = null;
        },
    },
    extraReducers: {
        [authenticationLogin.pending]: (state, action) => {
            state.login.isLoading = true;
            state.login.isSuccessful = false;
            state.login.data = null;
            state.login.error = null;
        },
        [authenticationLogin.fulfilled]: (state, action) => {
            state.login.isLoading = false;
            state.login.isSuccessful = true;
            state.login.data = action.payload;
            // state.login.error = null;

            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        [authenticationLogin.rejected]: (state, action) => {
            state.login.isLoading = false;
            // state.login.isSuccessful = false;
            // state.login.data = null;
            state.login.error = action.error;
        },
        [authenticationRegister.pending]: (state, action) => {
            state.register.isLoading = true;
            state.register.isSuccessful = false;
            state.register.data = null;
            state.register.error = null;
        },
        [authenticationRegister.fulfilled]: (state, action) => {
            state.register.isLoading = false;
            state.register.isSuccessful = true;
            state.register.data = action.payload;
            // state.register.error = null;

            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        [authenticationRegister.rejected]: (state, action) => {
            state.register.isLoading = false;
            // state.register.isSuccessful = false;
            // state.register.data = null;
            state.register.error = action.error;
        }
    },
});

export default authenticationSlice.reducer;
export const { authenticationLogout } = authenticationSlice.actions;