import React from "react";
import WebLayout from "../../components/layout/web/index";
import mixer from "$assets/images/mixer.jpeg";
import ButtonCart from '../../components/buttons/cart/index';
import ButtonElevated from "../../components/buttons/elevated";
import IconBag from '../../components/icons/bag';

export const MixAndMasterDetailsPage = () => {
  return (
    <WebLayout>
        <div className="container my-5">
        <div className="row text-light">
            <div className="col-lg-4">
                <img src={mixer} alt="" width="100%" />
            </div>

            <div className="col-lg-8">
                <h1>Mixing & Mastering</h1>
                <h3>Song Mixing by Beatzon</h3>
                <p>Get your song professional mixed and mastered. </p>
                <p>Vocals (Wav Stems/ Trackouts) and .wav or .mp3 of the beat only.</p>
                <p>
                Please follow these steps to ensure that your order is submitted
                successfully: Add your mixing order to the cart and complete payment.
                Email me the files for the order (Vocals + Beat in a single WAV or Mp3
                File). Include any additional information.
                </p>
                <p>
                <a href="mailto:info@beatzon.com">info@beatzon.com</a>
                </p>
                <p>
                We will confirm receipt of the payment and order details. Please allow
                up to 4-7 business days for turnaround.
                </p>
                <p>
                NOTE Service only includes 3 Revisions. If you want send rerecord
                vocals or resung vocals after service has already started you will be
                required to order again. Please be sure your vocals are recorded
                exactly the way you want them to avoid any issues.
                </p>

                <div className="my-2">
                    
                    <ButtonElevated icon={IconBag} title="300 USD" />
                </div>
            </div>
            </div>
        </div>
    </WebLayout>
  );
};
