import React from 'react'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import HomePage from './presentation/pages/home/home';
import '$assets/scss/main.scss';
import AudioPlayer from './presentation/components/player';
import { LicenceModalComponent } from './presentation/components/licenceModal';
import { ContactPage } from './presentation/pages/contact';
import { BeatsPage } from './presentation/pages/beats/index';
import { TutorialsPage } from './presentation/pages/tutorials/index';
import { SoundKitsPage } from './presentation/pages/soundkits/index';
import { BlogPage } from './presentation/pages/blog';
import { MixAndMasterPage } from './presentation/pages/mix_and_master/index';
import { MixAndMasterDetailsPage } from './presentation/pages/mix_and_master/details';
import { BlogShow } from './presentation/pages/blog/show';
import { LoginPage } from './presentation/pages/login/index';
import { RegisterPage } from './presentation/pages/register/index';
import { CheckoutPage } from './presentation/pages/checkout';


const App = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route index element={<HomePage />}/>
            <Route path="beats" element={<BeatsPage />} />
            <Route path="tutorials" element={<TutorialsPage />} />
            <Route path="soundkits" element={<SoundKitsPage />} />
            <Route path="mix-and-master" element={<MixAndMasterPage />} />
            <Route path="mix-and-master/details" element={<MixAndMasterDetailsPage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/:id" element={<BlogShow />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="checkout" element={<CheckoutPage />} />

            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
        </Routes>
        <AudioPlayer />
        <LicenceModalComponent />
    </BrowserRouter>
  )
}

export default App;