import React from 'react'
import styles from './index.module.scss';

const HomePageSoundKit = ({soundkit}) => {
  return (
    <div className={styles.kit}>
        <img src={soundkit.cover} alt="" />
        <h5>{soundkit.name}</h5>
        <h6>$80.00</h6>
    </div>
  )
}

export default HomePageSoundKit;