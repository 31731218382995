import React from 'react'
import WebLayout from '../../components/layout/web'
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import BeatRow from '../home/components/beatrow';
import { useEffect } from 'react';
import { beatFetchBeats } from '../../../application/features/beats';

export const BeatsPage = () => {
    // redux
    const dispatch = useDispatch();
    const beats = useSelector(state => state.beats.beats.data);

    // effecgs
    useEffect(() => {
        if (beats.length > 0) return;
        dispatch(beatFetchBeats());
    }, []);
    return (
        <WebLayout>
            <div className="section">
                <h1 className='text-light text-center my-5'>Beats</h1>

                <div className="d-flex gap-2 justify-content-center">
                    <button className="btn btn-secondary">All Types</button>
                    <button className="btn btn-secondary">All BPM</button>
                    <button className="btn btn-secondary">All Genres</button>
                    <button className="btn btn-secondary">Sort By</button>
                </div>

                <div className={styles.search}>
                    <input type="text" className="form-control" placeholder='What type of track are you looking for ?' width="300px" />
                </div>

                <div className={styles.beats}>
                    {beats.map(beat => <BeatRow beatId={beat.id} title={beat.name} cover={beat.cover} time={beat.duration} bpm={beat.bpm} tags={beat.tags.split(",")} media_url={beat.media} />)}
                </div>
            </div>
        </WebLayout>
    );
}
